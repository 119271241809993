import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { CalculationState, CalculationType } from './calculation.types';

const initialState: CalculationState = {
  type: 'calculation',
  room: null,
  arrival: null,
  departure: null,
  adults: 2,
  babies: [],
  name: '',
  phone: '',
  policyChecked: false,
  ui: {
    calculationOpened: false,
    arrivalOpened: false,
    departureOpened: false,
    babiesOpened: false,
  },
};

export const calculationSlice = createSlice({
  name: 'calculation',
  initialState,
  reducers: {
    setCalculationArrival: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.arrival = payload;
    },
    setCalculationDeparture: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.departure = payload;
    },

    changeCalculationAdults: (state, { payload }: PayloadAction<number>) => {
      state.adults = payload;
    },
    changeCalculationBabies: (state, { payload }: PayloadAction<number>) => {
      if (payload > state.babies.length) {
        state.babies = [
          ...state.babies,
          ...Array(payload - state.babies.length).fill(0),
        ];
      } else if (payload < state.babies.length) {
        state.babies = state.babies.filter((_, idx) => idx < payload);
      }
    },
    setCalculationBaby: (
      state,
      { payload }: PayloadAction<{ idx: number; age: number }>
    ) => {
      state.babies = [
        ...state.babies.slice(0, payload.idx),
        payload.age,
        ...state.babies.slice(payload.idx + 1),
      ];
    },

    setCalculationName: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },
    setCalculationPhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload;
    },
    toggleCalculationPolicy: (state) => {
      state.policyChecked = !state.policyChecked;
    },

    openCalculationArrival: (state) => {
      state.ui.arrivalOpened = true;
    },
    closeCalculationArrival: (state) => {
      state.ui.arrivalOpened = false;
    },

    openCalculationDeparture: (state) => {
      state.ui.departureOpened = true;
    },
    closeCalculationDeparture: (state) => {
      state.ui.departureOpened = false;
    },

    openCalculationBabies: (state) => {
      state.ui.babiesOpened = true;
    },
    closeCalculationBabies: (state) => {
      state.ui.babiesOpened = false;
    },

    openCalculation: (
      state,
      { payload }: PayloadAction<{ type: CalculationType; room?: string }>
    ) => {
      state.type = payload.type;
      state.room = payload.room ?? null;
      state.ui.calculationOpened = true;
    },
    closeCalculation: (state) => {
      state.ui.calculationOpened = false;
    },
    clearCalculation: (state) => {
      state.room = null;
      state.arrival = null;
      state.departure = null;
      state.adults = 2;
      state.babies = [];
      state.name = '';
      state.phone = '';
      state.policyChecked = false;
    },
  },
});

export const {
  setCalculationArrival,
  setCalculationDeparture,

  changeCalculationAdults,
  changeCalculationBabies,
  setCalculationBaby,

  setCalculationName,
  setCalculationPhone,
  toggleCalculationPolicy,

  openCalculationArrival,
  closeCalculationArrival,

  openCalculationDeparture,
  closeCalculationDeparture,

  openCalculationBabies,
  closeCalculationBabies,

  openCalculation,
  closeCalculation,
  clearCalculation,
} = calculationSlice.actions;

export const selectCalculation = (state: RootState) => state.calculation;

export const calculationReducer = calculationSlice.reducer;
