import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CloseButton } from 'components/elements/closeButton/CloseButton';
import { closeReview, selectReview } from '../review.slice';
import styles from './reviewBody.module.scss';

export const ReviewBody = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const review = useAppSelector(selectReview);

  return (
    <div className={styles.module}>
      <CloseButton
        className={styles.close}
        onClick={() => dispatch(closeReview())}
      />

      <div className={styles.top}>
        <span className={styles.name}>{review.name}</span>
        <span className={styles.date}>{review.date}</span>
      </div>

      <p className={styles.text}>{review.text}</p>
      <p className={styles.grade}>Оценка {review.grade} из 5</p>
    </div>
  );
};
