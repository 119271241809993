import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
// import * as serviceWorker from './serviceWorker';

import header from './scripts/header';
import gallery from './scripts/gallery';
import rooms from './scripts/rooms';
import cafe from './scripts/cafe';
import gorgippia from './scripts/gorgippia';
import instagram from './scripts/instagram';
import reviews from './scripts/reviews';

import './styles/index.scss';
import { Quiz } from 'features/quiz/Quiz';
import { Question } from 'features/question/Question';

header.init();
gallery.init();
rooms.init();
cafe.init();
gorgippia.init();
instagram.init();
reviews.init();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Quiz />
    </Provider>
  </StrictMode>,
  document.getElementById('quiz')
);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Question />
    </Provider>
  </StrictMode>,
  document.getElementById('question')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
