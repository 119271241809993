import { useAppDispatch } from 'app/hooks';
import { Calculation } from 'features/calculation/Calculation';
import { Callback } from 'features/callback/Callback';
import { openCalculation } from 'features/calculation/calculation.slice';
import { openCallback } from 'features/callback/callback.slice';
import { openFromReact } from 'utils/helpers/browser.helpers';
import { TextModal } from 'features/common/textModal/TextModal';
import { Thanks } from 'features/common/thanks/Thanks';
import { Cookie } from 'components/elements/cookie/Cookie';
import { openAddReview } from 'features/addReview/addReview.slice';
import { Review } from 'features/review/Review';
import { AddReview } from 'features/addReview/AddReview';
import { openTextModal } from 'features/common/common.slice';
import { openReview } from 'features/review/review.slice';

function App() {
  const dispatch = useAppDispatch();

  openFromReact('.header__callback', () => dispatch(openCallback()));
  openFromReact('.screen__calculation', () =>
    dispatch(openCalculation({ type: 'calculation' }))
  );
  openFromReact('.screen__booking', () =>
    dispatch(openCalculation({ type: 'booking' }))
  );
  openFromReact('.rooms__calculation', ({ currentTarget }) => {
    if (currentTarget instanceof HTMLElement) {
      dispatch(
        openCalculation({
          type: 'calculation',
          room: currentTarget.dataset.room,
        })
      );
    }
  });
  openFromReact('.rooms__booking', ({ currentTarget }) => {
    if (currentTarget instanceof HTMLElement) {
      dispatch(
        openCalculation({
          type: 'booking',
          room: currentTarget.dataset.room,
        })
      );
    }
  });
  openFromReact('.reviews__button', ({ currentTarget }) => {
    if (currentTarget instanceof HTMLElement) {
      dispatch(
        openReview({
          name: currentTarget.dataset.name ?? '',
          grade: currentTarget.dataset.grade ?? '',
          date: currentTarget.dataset.date ?? '',
          text: currentTarget.dataset.text ?? '',
        })
      );
    }
  });
  openFromReact('.reviews__add', () => dispatch(openAddReview()));
  openFromReact('.footer__callback', () => dispatch(openCallback()));
  openFromReact('#footer-policy', () => dispatch(openTextModal('policy')));
  openFromReact('#footer-rules', () => dispatch(openTextModal('rules')));
  openFromReact('#footer-cookie', () => dispatch(openTextModal('cookie')));

  return (
    <>
      <Callback />
      <Calculation />
      <Review />
      <AddReview />

      <TextModal />
      <Thanks />
      <Cookie />
    </>
  );
}

export default App;
